export default {
  markup: `
                <section class="section container" id="kont">
                <h1 class="section-title">Kontakt</h1>
                <div class="row">
                    <div class="col-xs-12 col-md-4 vert-offset-bottom-1">
                        <div class="card adresa">
                            <i data-feather="home"></i>
                            <p>Dubrave,8
                                <br>Čilipi 20213 </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4 vert-offset-bottom-3">
                        <div class="card">
                        <i data-feather="mail"></i>
                            <p>info@bugovina.hr</p>
                            <a href="mailto:info@bugovina.hr" class="btn btn-primary">
                                <span class="glyphicon glyphicon-envelope" aria-hidden="true"></span> Pošalji mail</a>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4 vert-offset-bottom-3">
                        <div class="card">
        <i data-feather="phone"></i>
                            <p>Tel: +385020771680
                                <br>Fax: +385020772210 </p>
                            <a href="tel:+385-020-771680" class="btn btn-primary">
                                <span class="glyphicon glyphicon-phone" aria-hidden="true"></span> Nazovi</a>
                        </div>
                    </div>
                </div>
                </section>
    `
};
