import convotherm from "./convotherm/all";
import winterhalter from "./winterhalter/all";

export default {
  convotherm,
  winterhalter,
  /**
   * Find by badge
   */
  pick: function({ manufacturer, badge }) {
    return this[manufacturer].find(prod => prod.badge === badge).modal;
  }
};
