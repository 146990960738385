import uc from "../../images/uc.jpg";
import pt from "../../images/pt.jpg";
import gs from "../../images/gs.jpg";
import tracni from "../../images/tracni.jpg";
import tt from "../../images/tt.jpg";
import pranje from "../../images/pranje.jpg";
import kosare from "../../images/kosare.jpg";

export default [
  {
    badge: "UC",
    modal: {
      title: "Potpultne perilice UC serije",
      body: `
        <img src="${uc}" class="img-responsive  product-thumbnail" alt="UC serija perilica">
        <p>Potpultne perilice posuđa UC serije dostupne su kao perilice čaša, perilice posuđa, perilice za bistroe ili perilice pribora za jelo u veličinama <strong>S</strong>,<strong>M</strong>,<strong>L</strong> i<strong>XL</strong>.<br><br>Tri standardna programa pranja mogu se automatski podešavati ovisno ostupnju onečišćenja i vrsti predmeta za pranje. Možete se osloniti naperilice posuđa koje su optimalno prilagođene vašim individualnim potrebama.Osigurani su savršeni rezultati pranja.<br> <br>Perilicu možete preciznije prilagoditi izborom dodatne opreme. UC serija - ekonomično rješenje za sjajne rezultate pranja. </p> <br>
        <h3>Dimenzije</h3>
        <p>Ovisno o raspoloživom prostoru, kapacitetu i količini posuđa dostupne su četiri veličine perilica, S, M, L i XL. <br>UC-S (Š: 460 mm, D: 637 mm,V: 725-760 mm) izvedba Energy V: 810-845 mm <br>UC-M (Š: 600 mm, D: 637 mm, V: 725-760 mm) izvedba Energy V: 810-845 mm <br>UC-L (Š: 600 mm, D: 637 mm, V: 820-855 mm) izvedba Energy V: 905-940 mm <br>UC-XL (Š: 600 mm, D: 637 mm, V: 820-855 mm) izvedba Energy V: 905-940 mm Osobito prikladna za pranje limova i pladnjeva. </p>
        `
    }
  },
  {
    badge: "PT",
    modal: {
      title: "Protočne perilice PT serije",
      body: `
            <img src="${pt}" class="img-responsive product-thumbnail" alt="PT serija perilica">
            <p> Protočne perilice posuđa PT serije osiguravaju savršene rezultate pranja u najkraćem vremenu zahvaljujući svojim inovativnim značajkama. Osim toga, ove perilice nude maksimalnu učinkovitost u kuhinji.
                <br>
                <br>Ovisno o predmetima pranja, raspoloživom prostoru i individualnim potrebama, moguće je birati između modela PT-M, PT-L, PT-XL i PT-500. Perilice PT serije imaju brojne značajke i opcije, te se mogu optimalno prilagoditi vašim zahtjevima.
                <br>
                <br>Inovativni dizajn perilica PT serije osigurava vrhunsku izvedbu za savršene rezultate pranja - sjajno, brzo i učinkovito. </p>
            <br>
            <a href="http://media.winterhalter.biz/mc/mediabase?assetId=145893&disposition=inline" target="_blank" class="btn btn-default">Službeni dokument</a>
            `
    }
  },
  {
    badge: "GS",
    modal: {
      title: "Perilice crnog posuđa GS serije",
      body: `
            <img src="${gs}" class="img-responsive  product-thumbnail" alt="GS serija perilica">
            <p> Perilice crnog posuđa GS 600 serije higijenski čisto peru posuđe kao što su lonci, limovi i GN posude.
                <br>
                <br>Snažan sustav pranja s zakretnim rukama pranja svladava čak i tvrdokorne naslage. Optimizirani tijek rada u kombinaciji s učinkovitim profesionalnim perilicama posuđa osiguravaju trajno niske operativne troškove u pekarnicama, mesnicama i ugostiteljskim
                objektima.
                <br>
                <br>Snažna i učinkovita GS 600 serija pruža vam vrhunski higijenski koncept.
            </p>
            <h3>Značajke</h3>
            <ul>
                <li>Upravljanje jednom tipkom</li>
                <li>Prikaz temperature</li>
                <li>Zakretni sustav pranja</li>
                <li>Četverostruki sustav filtracije</li>
                <li>Program samočišćenja</li>
                <li>Termostop</li>
                <li>Zaštita od prskanja</li>
                <li>Ugrađeni dozator sredstva za pranje</li>
            </ul>
            <h3> Mogućnosti </h3>
            <ul>
                <li>Energy</li>
                <li>Pretpranje hladnom vodom</li>
                <li>Upozoravajući zvučni signal</li>
            </ul>
            `
    }
  },
  {
    badge: "TP",
    modal: {
      title: "Perilice s tračnim prijenosom košara",
      body: `
            <img src="${tracni}" class="img-responsive  product-thumbnail" alt="Perilica s tračnim prijenosom">
            <p>Perilice s tračnim prijenosom košara s jednim spremnikom. Kompaktna i visokoučinkovita rješenja. Perilice s tračnim prijenosom košara s jednim spremnikom STR serije pružaju visoku učinkovitost i veliku snagu u kompaktnoj veličini.
            </p>
            <p> Snažan sustav pranja sa savršeno prilagođenim dijelovima pruža najbolje rezultate pranja u kratkom vremenu. Perilica s tračnim prijenosom košara s jednim spremnikom dostatnog je i fleksibilnog kapaciteta za veća punjenja. </p>
            <p> Kompaktne dimenzije i različite opcije podešavanja značajke su STR serije, prema kojima perilice mogu stati i u male ili kutne prostore. Osim toga, područje pranja koje se aktivira prolazom košare osigurava ekonomičnu potrošnju i niske operativne troškove.
            </p>
            `
    }
  },
  {
    badge: "TT",
    modal: {
      title: "Perilice s transportnom trakom",
      body: `
            <img src="${tt}" class="img-responsive  product-thumbnail" alt="Perilice s transportnom trakom">
            <p>Perilice s transportnom trakom za prijenos posuđa. Savršena rješenja kod pranja posuđa u profesionalnoj kuhinji. Perilice s transportnom trakom poduzeća Winterhalter daju optimalna rješenja kod pranja u velikim restoranima, hotelima, kantinama i bolničkim
                ustanovama. </p>
            <p> Sustav pranja visoke učinkovitosti osigurava higijensko i savršeno čisto pranje velikih količina posuđa u najkraće mogućem vremenu. </p>
            <p>Ovi tehnički dobro razvijeni uređaji s transportnom trakom s optimiziranim sustavom ispiranja i aktiviranim nadzorom područja osiguravaju ekonomičnu potrošnju i maksimalnu učinkovitost.</p>
            `
    }
  },
  {
    badge: "PI",
    modal: {
      title: "Sredstva za pranje i ispiranje",
      body: `
            <img src="${pranje}" class="img-responsive product-thumbnail" alt="Sredstva za pranje i ispiranje">
            <p> Savršeni rezultati pranja. Maksimalna higijena. Kao stručni ponuditelj proizvoda i sustava za pranje posuđa, poduzeće Winterhalter proizvodi visokokvalitetna sredstva za pranje i higijenske proizvode. Dosljedni i sjajni rezultati pranja mogu se postići
                samo kada su savršeno usklađeni svi elementi u postupku pranja. </p>
            <p> Uz dugogodišnje iskustvo nudimo isproban i ispitan visokokvalitetni asortiman sredstava i proizvoda za čišćenje. Oni su visokoučinkoviti i od bitne su važnosti za pružanje savršenih rezultata pranja i smanjenje razine troškova. </p>
            `
    }
  },
  {
    badge: "KW",
    modal: {
      title: "Košare Winterhalter",
      body: `
            <img src="${kosare}" class="img-responsive product-thumbnail" alt="Winter halter košare">
            <p>Košare za pranje posuđa Winterhalter. Odgovarajuća košara za odgovarajući posao. </p>
            <p>Sveobuhvatni asortiman košara poduzeća Winterhalter osigurava raspoloživost košara za svaku situaciju.</p>
            <p>Svaka košara posebno je osmišljena za specifičnu upotrebu ili individualan način pranja. Korištenje odgovarajuće košare za pojedino pranje može značajno utjecati na postizanje boljih rezultata pranja.</p>
            <p> Košare poduzeća Winterhalter pružaju funkcionalan dizajn, visoke su kvalitete i jednostavne su za korištenje. Košare otvorenog dizajna osiguravaju protočnost vode i kruženje zraka. Postižu se optimalni rezultati pranja i sušenja. </p>
            `
    }
  }
];
