import img from '../images/slikacrna2.png';
import mysvg from '../images/finisher.svg';

const mystyle = `

width: 100%;
background-image: url("${mysvg}");
background-size: cover;
height: 200px;
width: 100%;
`

export default {
    markup: `
<div class="hidden" style="${mystyle}">
</div>

<div id="finisher" class="container-fluid has-divider">
    <div class="row">
    <div class="col-md-12">
    <img src="${img}" class="img-responsive" style="margin: 4em auto;">
    <p class="text-center hidden"> sadržaja pridržano. </p>
    </div>
    </div>
    </div>
    `
}
