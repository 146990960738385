import myImage from '../images/DSC05475.jpg'
import imageTwo from '../images/DSC05476.jpg'
import imageThree from '../images/DSC05520.jpg'
import imageFour from '../images/DSC05527.jpg'

let initGallery = function() {
  let items = [];
  var pswpElement = document.querySelectorAll('.pswp')[0];

  // define options (if needed)
  var options = {
    // optionName: 'option value'
    // for example:
    index: 0 // start at first slide
  };



  items = images.referentnaGalerija

  console.warn("inside init gallery ", pswpElement)
  var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
  gallery.listen('gettingData', function(index, item) {
    if (item.w < 1 || item.h < 1) { // unknown size
      var img = new Image();
      img.onload = function() { // will get size after load
        item.w = this.width; // set image width
        item.h = this.height; // set image height
        gallery.invalidateCurrItems(); // reinit Items
        gallery.updateSize(true); // reinit Items
      }
      img.src = item.src; // let's download image
    }
  });

  gallery.init();
}

const images = {
  referentnaGalerija: [{
      src: myImage,
      w: 0,
      h: 0
    },
    {
      src: imageTwo,
      w: 0,
      h: 0
    },
    {
      src: imageThree,
      w: 0,
      h: 0
    },
    {
        src: imageFour,
        w: 0,
        h: 0
      },
  ],
}

export default {
  initGallery
}