import Modal from "../components/modal";

export default {
  markup: `
  <section class="section container has-divider" id="zastupništvo">
  <h2 class="section-title">Zastupništvo</h2>
  <div class="row">
      <div class="col-md-6">
          <div class="card-background winterhalter-img">
          </div>
          <!--                         <img src="images/winterhalter.png" class="img-responsive card-pic" alt="winterhalter slika"> -->
          <h3 class="card-title">Winterhalter</h3>
          <p> Winterhalter proizvodi su specijalizirani za profesionalne sustave pranja, čiji se naziv već desetljećima veže uz inovaciju, pouzdanost i kvalitetu uređaja proizvedenih u Njemačkoj i Švicarskoj. Winterhalter ima rješenja za ukupne sustave pranja, perilice posuđa, proizvode za pranje, pripreme vode i pribor. Na taj Vam način u svako doba osiguravamo higijenski čiste rezultate pranja na jednom mjestu. </p>
      </div>
      <div class="col-md-6">
          <div class="card-background convotherm-img">
              <!--                         <img src="images/convotherm.png" class="img-responsive card-pic" alt="convotherm slika"> -->
              <h3 class="card-title">Convotherm</h3>
              <p> Convotherm kombinirani parni uređaji za pripremu hrane u restoranima, hotelima i bolnicama. S trideset tri bazna modela, Convotherm nudi najveći izbor kombiniranih parnih uređaja, koji se nude u električnom ili plinskom priključku te oba u verziji os (šprici) ili ob (bojler). Za posebne uvjete, Convotherm je razvio „mini svijet“ koji nudi kvalitet Convotherm uređaja u najmanjim prostorima. </p>
          </div>
      </div>
  </div>
</section>
    `
};
