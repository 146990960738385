import $ from "jquery";
window.jQuery = $;
window.$ = $;

import load from "little-loader";

import mapStyle from './utils/map_style'

import mymarker from './design/bugmarker.png'
import origmarker from './design/logo/bugovina-original-logo.png'

load("https://maps.googleapis.com/maps/api/js?key=AIzaSyAB15wS-Y_pMnnLnmy4fPNdiPSr8eVn4c8", {
  setup: () => {},
  callback: () => {
    let bugovinaOnMap = {
      lat: 42.539371,
      lng: 18.29498520
    };

    let mapLocation = new google.maps.LatLng(
      bugovinaOnMap.lat,
      bugovinaOnMap.lng
    );
    let map;
    (function initMap() {
      map = new google.maps.Map(document.getElementById("map"), {
        center: mapLocation,
        zoom: 10,
        styles: mapStyle
      });


      let customMarker = {
        icon: mymarker,

      }
      let marker = new google.maps.Marker({
        position: mapLocation,
        map: map,
        title: "Bugovina d.o.o.",
        visible: true,
        icon: customMarker.icon
      });
      marker.setMap(map);
    })();
  }
});

//PHOTOSWIPE TODO:: USAGE with webpack https://github.com/dimsemenov/PhotoSwipe/issues/1374

import "bootstrap/dist/js/bootstrap.js";

/* import feather from 'feather-icons'; */

import style from "./styles/main.scss";

import navbar from "./sections/navbar";
import jumbotron from "./sections/jumbotron";
import tajna from "./sections/tajna";
import zastupnistvo from "./sections/zastupnistvo";
import o_nama from "./sections/o_nama";
import ponuda from "./sections/ponuda";
import reference from "./sections/reference";
import kontakt from "./sections/kontakt";
import mapa from "./sections/mapa";
import footer from "./sections/footer";
import finisher from "./sections/finisher";

import Modal from "./components/modal";

import products from "./products/index";
import {
  references
} from "./info";

import pswp from "./utils/gallery";
import pswpGallery from "./components/pswp_gallery";

window.onload = function() {
  /*
  Spinner
  */
  document.querySelector("#loader").style.display = "none";
  document.getElementById("app").style.display = "block";
};

document.addEventListener("DOMContentLoaded", function() {
  console.info("Crafted by https://malloc.hr");
  document.getElementById("app").innerHTML =
    navbar.markup +
    jumbotron.markup +
    tajna.markup +
    zastupnistvo.markup +
    o_nama.markup +
    ponuda.markup +
    reference.markup +
    //kontakt.markup +
    finisher.markup +
    `<div id="map"></div>` +
    mapa.markup +
    footer.markup +
    pswpGallery.markup +
    Modal.markup({
      title: "Naslov",
      body: "Tekst"
    });

  document.getElementById("show-refs").addEventListener("click", function() {
    Modal.refill("Reference", references.markup);
  });
  /**
   * Attach modals functionality to dropdowns
   */
  document.querySelectorAll("[data-target='#body-modal'").forEach(btn => {
    btn.addEventListener("click", () => {
      let manufacturer = btn.getAttribute("data-product-manufacturer");
      let badge = btn.getAttribute("data-product");

      let myproduct = products.pick({
        manufacturer: manufacturer,
        badge: badge
      });

      console.warn("MINE:", myproduct);
      Modal.refill(myproduct.title, myproduct.body);
    });
  });

  //DataSPY ACTIVE
  document.getElementsByTagName("body")[0].setAttribute("data-spy", "scroll");

  //2018 PLAIN es6 verison Just modern browsers
  /*     document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            console.warn("I AM clickinig")
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    }); */

  //jQuery smooth scroll
  //Fix navigation activating with this link FIXME:https://jsfiddle.net/cse_tushar/Dxtyu/141/
  $(document).on("click", 'a[data-scroll=true][href^="#"]', function(event) {
    event.preventDefault();
    let navbarOffset = 0;
/*             console.warn(this.parentElement)
        this.toggle('is-active'); */
    $("html, body").animate({
        scrollTop: $($.attr(this, "href")).offset().top - navbarOffset
      },
      280
    );
  });


  let galleries = document.querySelectorAll(".runPswp");

  galleries[0].addEventListener("click", function() {
    console.warn("Running gallery", pswp);
    pswp.initGallery();
  });
  /*    galleries[0].addEventListener('click', function() {
      pwsp.initGallery('apartmentOne');
   }); */
  /*    galleries[1].addEventListener('click', function() {
      pwsp.initGallery('apartmentTwo');
   }); */

  /*    ("https://maps.googleapis.com/maps/api/js?key=AIzaSyAB15wS-Y_pMnnLnmy4fPNdiPSr8eVn4c8&callback=initMap", { */

/*   section_scroller(); */
  feather.replace();
});