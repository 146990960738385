import { references } from "../info";

export default {
  markup: `
                <section class="section container" id="reference">
                <h2 class="section-title">Reference</h2>
                <div class="row">
                    <div class="col-md-6">
                        <i class="hidden" data-feather="star"></i>

                        <p>Liste poduzeća i udruga s kojima poslujemo.</p>
                        <button id="show-refs" type="button" class="btn btn-default" data-target="#body-modal" data-toggle="modal">Reference</button>
                    </div>
                    <div class="col-md-6">
                        <i class="hidden" data-feather="image"></i>

                        <p>Pregled fotografija.</p>
                        <button type="button" class="btn btn-default runPswp">
                        Galerija
                        </button>
                    </div>
                    <div class="col-md-6 hidden">
                            <p class="adress">Dubrave 8, Čilipi 20213
                            <br>
                            info@bugovina.hr
                            <br>
                            Tel: +385020771680
                             Fax: +385020772210
                            </p>
                    </div>
                </div>
                </section>
    `
};
