import minisvijet from '../../../images/minisvijet.jpg'
import touch from '../../../images/touch.jpg'
import linijaEasy from '../../../images/linijaEasy.jpg'
import podaci from '../../../images/podaci.jpg'
import easyDial from '../../../images/easydial.jpg'
import dial from '../../../images/dial.jpg'
export default [{
    badge: 'MS',
    modal: {
        title: 'Mini svijet',
        body: `
        <img src="${minisvijet}" class="img-responsive product-thumbnail" alt="Mini Convotherm">
        <p> Mini parno-konvekcijska peć CONVOTHERM nudi mogućnosti većih uređaja u najmanjim prostorima. Opremljena s dokazanim visokotehnološkim rješenjima daje pouzdanu podršku svakodnevnoj kuhinjskoj rutini.
            <br>
            <br>Najmanje dimenzije, najbolji rezultati kuhanja i sofisticirano upravljanje - mini parno-konvekcijske peći inspiriraju. Sa tri programa za kuhanje, regeneracijskom funkcijom, funkcijom kuhanja preko noći i delta-T kuhanjem spektar primjena je neograničen.
            <br>
            <br>Nenadmašni rezultati kuhanja će vas oduševiti! Od pjenastog suffle-a, do čvrstog povrća ili jače pečenog francuskog krumpira, sve je savršeno pečeno - zahvaljujući jedinstvenoj tehnologiji zatvorenog sustava (Advanced Closed Sistem).
            <br>Naša mini parno-konvekcijska peć također je dostupna i kao opcija sa upravljanjem putem dodirnog zaslona - easyTouch. </p>
        <br>
        <h1>OES mini</h1>
        <h2>6.06 mini</h2>
        <p>OES 6.06 mini Vam nudi minimum prostora sa maksimalnim učinkom. OES 6.06 mini je najmanja električna parno-konvekcijska peć s mogućnosti prihvata gastro posuda 2/3 GN (gastro-norma).</p>
        <h2>6.06 mini mobil</h2>
        <p>OES 6.06 mini mobil je savršena parno-konvekcijska peć za pripremanje svježe hrane na različitim lokacijama. Zbog integrirane ladice za vodu nije potreban fiksni priključak. OES 6.06 mini mobil je najmanja električna parno-konvekcijska peć s mogućnosti
            prihvata gastro posuda 2/3 GN (gastro-norma).</p>
        <h2>6.10 mini</h2>
        <p>OES 6.10 mini nudi Vam minimum prostora sa maksimalnim učinkom. OES 6.10 mini je najmanja električna parno-konvekcijska peć s mogućnosti prihvata gastro posuda 1/1 GN (gastro-norma).</p>
        <h2>6.10 mini 2u1</h2>
        <p>Uz OES 6.10 mini 2u1easyTouch, možete biti sigurni zahvaljujući dvije odvojene komore za preciznu pripremu vaših obroka bez čekanja, čak i s različitim metodama kuhanja. Upravljanje je jednostavno zahvaljujuci EasyTouch – ekranu osjetljivom na dodir.
            Obje komore za pripremu obroka se upravljaju putem jednog upravljačkog polja.</p>
        <h2>6.10 mini mobil</h2>
        <p>OES 6.10 mini mobil je savršena parno-konvekcijska peć za pripremanje svježe hrane na različitim lokacijama. Zbog integrirane ladice za vodu nije potreban fiksni priključak. OES 6.10 mini mobil je najmanja električna parno-konvekcijska peć s mogućnosti
            prihvata gastro posuda 1/1 GN (gastro-norma).</p>
        <h2>OES 10.10 mini</h2>
        <p>OES 10.10 mini je čudo u svijetu mini verzija. Zahvaljujući veličini to je idealna jedinica u najmanjem prostoru. </p>
        `
    }
},{
    badge: 'ET',
    modal: {
        title: 'Convotherm 4 Easy Touch',
        body: `
        <img src="${touch}" class="img-responsive product-thumbnail" alt="Easy Touch Convotherm">
        <p> Korisničko sučelje easyTouch
            <br>
            <br>Kao pionir tehnologije dodirnih zaslona kod parno-konvekcijskih peći, Convotherm je dodao novu dimenziju praktičnosti u odabiru operacija.
            <br>
            <br>Kompletan paket kod izvedbe easyTouch pruža cijeli niz impresivnih i praktičnih značajki, uključujući 9" dodirni zaslon visoke rezolucije i mogućnost prilagodbe funkcija prema vašim potrebama. Operativni koncept je nevjerojatno jednostavan i sjedinjuje
            postojeće i nove funkcije.
            <br>
            <br>- ACS+ jedinstveni zatvoreni sustav uključuje i usklađene sljedeće funkcije:
            <br>
            <br>Crisp&Tasty funkcija - za jela koja moraju biti hrskava izvana, a sočna iznutra Bake Pro - program u 5 razina za pekarske proizvode HumidityPro - program podešavanja vlage u 5 razina Ventilator za osjetljive namirnice - podešavanje brzina vrtnje
            u 5 razina - ConvoClean+ poboljšan sustav automatskog čišćenja koje nudi higijensku sigurnost </p>
        <img src="${linijaEasy}" class="img-responsive" alt="">
        <br>
        <img src="${podaci}" class="img-responsive" alt="">
        `
    }
},
{
    badge: 'ED',
    modal: {
        title: 'Convotherm Easy Dial',
        body: `
        <img src="${easyDial}" class="img-responsive product-thumbnail" alt="Easy Dial Convotherm">
        <p>Korisničko sučelje easyDial</p>
        <p> EasyDial je novi standard kod ručnog upravljanja! </p>
        <p> Zahvaljujući pametnom dizajnu EasyDial kontrola možete brzo podesiti Vaš profil za kuhanje. Sve funkcije su dostupne u jednoj razini. Moguće je vidjeti prikaz svih programa u svakom trenutku. </p>
        <p> easyDial regeneracijska funkcija: regenerira proizvode do njihove najviše razine
            <br>Programibilna kuharica s 99 recepata
            <br>TriColor indikator - indikator procesa kuhanja u tri boje </p>
        <img src="${dial}" class="img-responsive" alt="Easy Dial Convotherm">
        `
    }
}]
