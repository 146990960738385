export default {
  markup: `
                <section class="section container" id="onama">
                <h2 class="section-title">O nama</h2>

                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <!--Ovdje je išao carousel-->
                        <p> Tvrtka
                            <strong>Bugovina d.o.o.</strong> djeluje na području Dubrovačko - neretvanske županije od 2002. godine.
                            Bavimo se prodajom, montažom i servisom profesionalne ugostiteljske opreme, a u svom prodajnom
                            programu nudimo strojeve za pranje posuđa
                            <strong>Winterhalter Gastronom</strong> i parno - konvekcijske peći
                            <strong>Convotherm</strong>. Ponuda tvrtke prilagođena je potrebama modernog tržišta.
                            <br><br>
                            U našem prodajno - servisnom centru stojimo na raspolaganju svojim sadašnjim i budućim klijentima
                            koji će od stručno educiranih zaposlenika dobiti sve potrebne informacije. Unutar našeg poslovnog
                            prostora nalazi se skladište originalnih rezervnih dijelova za sve proizvode prodajnog asortimana.
                            Za svu opremu iz našeg asortimana posjedujemo ateste Zavoda za ispitivanje kvalitete, tehničku
                            dokumentaciju i upute za rad na hrvatskom jeziku. </p>
                    </div>

                </div>
            </section>


            <div class="about-us-img">

            </div>
    `
};
