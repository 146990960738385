export default {
    markup: `
    <form 
    action="https://formspree.io/hrvoje.glavinja@bugovina.hr" 
    method="POST" class="form-horizontal"
    >
                    <div class="form-group">
                      <label for="email" class="col-sm-2 control-label hidden">Email</label>
                      <div class="col-sm-10">
                        <input type="email" class="form-control" name="e-pošta" placeholder="Email">
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="name" class="col-sm-2 control-label hidden">Ime</label>
                      <div class="col-sm-10">
                        <input type="name" class="form-control" name="ime" placeholder="Unesite vaše ime">
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="message" class="col-sm-2 control-label hidden">Poruka</label>
                      <div class="col-sm-10">
                      <textarea class="form-control" rows="3" id="message" name="poruka" placeholder="Napišite svoju poruku..."></textarea>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="col-sm-10">

                        <div class="btn-group" role="group" aria-label="...">
                          <button type="submit" value="Send" class="btn btn-default">Pošalji</button>
                          <button type="reset" value="Reset" class="btn btn-light">Očisti</button>
                        </div>
                      </div>
                    </div>
                  </form>
    `
}
