export default {
  markup: `

            <section id="tajna" class="section container has-divider">
                <h2 class="section-title">Tajna</h2>
                <div class="row">
                    <div class="col-md-4">
                        <div class="story-card">
                            <i class="secret-icon" data-feather="user"></i>
                            <h3 class="card-title"> Klijenti</h3>
                            <p>Naš zadatak, ali i strast jest riješiti vaš problem i ponuditi vam brza, pouzdana i sigurna rješenja.
                                Takav rasplet, međutim, nebi bio ostvariv da nam klijenti nisu na prvom mjestu. Poduža lista
                                referenci je ono što nas ohrabruje, motivira, ali i daje teret izazova koje nas svaki radni
                                dan iznova vode u nove pustolovine.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="story-card">

                            <i class="secret-icon" data-feather="settings"></i>
                            <h3 class="card-title">Znanje</h3>
                            <p>Svakodnevne izazove, koji su pred nas postavljeni, bilo bi nemoguće obaviti bez kompetentnosti
                                i vještine za koju je osposobljen naš stručni tim servisera. Uz znanje, iskustvo je ono što
                                nas odvaja od drugih te daje garanciju, da će odabrana rješenja biti implementirana na siguran
                                i profesionalan način.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="story-card">

                            <i class="secret-icon" data-feather="watch"></i>
                            <h3 class="card-title"> Brzina</h3>
                            <p>U modernom svijetu, sigurnost i pouzdanost su uvijet nužnosti. U vremenu kada je svaka sekunda
                                bitna, on nije i dovoljan. Da bi bili među najboljima, uz kvalitetu i profesionalnost, potrebno
                                je posjedovati i brzinu rješenja. Uz zadovoljstvo klijenata i profesionalnost rada, brzina
                                izvođenja je ono što naš recept čini kompletnim.</p>
                        </div>
                    </div>
                </div>

            </section>
    `
};
