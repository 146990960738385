function Template(cb) {
  return function(data) {
    const dataKeys = [];
    const dataVals = [];
    for (let key in data) {
      dataKeys.push(key);
      dataVals.push(data[key]);
    }
    let func = new Function(...dataKeys, "return (" + cb + ")();");
    return func(...dataVals);
  };
}

export default {
  markup: Template(
    () => `
        <div class="modal fade" id="body-modal" role="dialog">
              <div class="modal-dialog">
                  <!-- Modal content-->
              <div class="modal-content">
                      <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal">&times;</button>
                          <h4 class="modal-title">${title}</h4>
                      </div>
                      <div class="modal-body">
                          ${body}
                      </div>
                      <div class="modal-footer">
                          <button type="button" class="btn btn-default" data-dismiss="modal">Zatvori</button>
                      </div>
                  </div>
              </div>
          </div>
      `
  ),
  refill: function(title, body) {
    document
      .getElementById("body-modal")
      .querySelector(".modal-title").innerHTML = title;
    document
      .getElementById("body-modal")
      .querySelector(".modal-body").innerHTML = body;
  },
};

/* let modal = function(title, body) {
  return `<div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
        <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">${title}</h4>
                </div>
                <div class="modal-body">
                    ${body}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>`;
};
export default function(title, body) {
  return modal`naslov ovoga`;
} */

/* export default class Modal {
  constructor(title, body) {
    this.title = title;
    this.body = body;
    this.markup = this.render(title, body);
    console.warn("Created");
  }
  render(title, body) {
      console.warn("Rendering ", title,body)
    return `<div class="modal fade" id="myModal" role="dialog">
          <div class="modal-dialog">
              <!-- Modal content-->
        <div class="modal-content">
                  <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                      <h4 class="modal-title">${title}</h4>
                  </div>
                  <div class="modal-body">
                      ${body}
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                  </div>
              </div>
          </div>
      </div>`;
  }
  refill(title, body) {
    console.warn("this-> ", this);
    this.title = title;
    this.body = body;
    this.markup = this.render(title, body);
  }
}
 */
