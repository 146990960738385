import logo from "../images/bug.png";
import logoTwo from '../images/gray.png'
export default {
  markup: `
<nav class="navbar navbar-inverse navbar-fixed-top">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <!--  <a class="navbar-brand pull-left" href="../images/bug.png">
                            <img class="brander" src="brander.png" alt="Logo Tvrtka Bugovina"> </a> -->
      <a href="#onama" class="navbar-brand pull-left">
                            <img src="${logoTwo}" class="brander">
                            <!--Bugovina-->
                        </a>
    </div>
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav"> </ul>
      <ul class="nav navbar-nav navbar-right">
        <li><a href="#tajna" data-scroll="true" class="navbar-link">Tajna</a></li>
        <li><a href="#zastupništvo" data-scroll="true" class="navbar-link">Zastupništvo</a></li>
        <li><a href="#onama" data-scroll="true" class="navbar-link">O nama</a></li>
        <li><a href="#ponuda" data-scroll="true" class="navbar-link">Ponuda</a></li>
        <li><a href="#reference" data-scroll="true" class="navbar-link">Reference</a></li>
        <li><a href="#kont" data-scroll="true" class="navbar-link">Kontakt</a></li>

        <!--
                            <li class="active">
                                <a href="#tajna" class="anchorLink">Tajna
                                </a>
                            </li>
                            <li>
                                <a href="#zastupništvo" class="anchorLink">Zastupništvo</a>
                            </li>
                            <li>
                                <a href="#onama" class="anchorLink">O nama</a>
                            </li>
                            <li>
                                <a href="#ponuda" class="anchorLink">Ponuda</a>
                            </li>
                            <li>
                                <a href="#reference" class="anchorLink">Reference</a>
                            </li>
                            <li>
                                <a href="#kont" class="anchorLink">Kontakt</a>
                            </li>
                            -->
      </ul>
    </div>
  </div>
</nav>
`
};