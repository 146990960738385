import logo from "../images/gray.png";

const facebookPage = 'https://web.facebook.com/Bugovina-doo-1551682755106794/?ref=br_rs';
const googlePlusPage = 'https://plus.google.com/112177540781622179391';
export default {
  markup: `
                <div id="footer">
                <div class="container">
                    <h2 class="section-title hidden">Zaglavlje</h2>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="footer-list">
                                <h5 class="title">Info</h5>
                                <ul>
                                    <li>Dubrave 8<br>Čilipi 20213</li>
                                    <br>
                                    <li>info@bugovina.hr</li>
                                    <br>
                                    <li>Tel: +385 020 771 680</li>
                                    <li>Fax: +385 020 772 210</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="footer-list">
                                <h5 class="title">Poglavlja</h5>
                                <ul>
                                <li><a href="#tajna">Tajna</a></li>
                                <li><a href="#zastupnistvo">Zastupništvo</a></li>
                                <li><a href="#kont">Kontakt</a></li>
                                <li><a href="#reference">Reference</a></li>
                                <li><a href="#kont">Kontakt</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="footer-list">
                                <h1 class="title">Mreže</h1>
                                <ul class="social-list">
                                    <li><a href="${facebookPage}"><i class="footer-icon" data-feather="facebook"></i></a></li>
                                    <li><a href="${googlePlusPage}"><i class="footer-icon" data-feather="plus"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="footer-list">
                                <img src="${logo}" class="img-responsive" style="padding: 0.5em 0em;">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-left">
                        <h6 style="color: grey;">Sva prava pridržana &copy; 2016.</h6>
                            <h6 class="crafted">
                                <!--<a href="">bugovina d.o.o</a>-->
                            </h6>
                        </div>

                        <div class="col-md-6 text-right">
                        <h6 style="color: grey;">Crafted by <a href="https://malloc.hr">malloc.hr</a></h6>
                            <h6 class="crafted">
                                <!--<a href="">bugovina d.o.o</a>-->
                            </h6>
                        </div>
                        </div>
                    <div class="row hidden">
                        <div class="col-md-12">
                        <h6 style="color: grey;">crafted by</h6>
                            <h6 class="crafted">
                                <a href="">malloc.hr</a>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="under-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                        <h6 class="hidden" style="color: grey;">crafted by</h6>
                        <h6 class="crafted">
                            crafted by <a href="https://malloc.hr">malloc.hr</a>
                        </h6>
                        </div>
                    </div>
                </div>
            </div>
    `
};
