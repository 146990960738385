export default {
    markup: `
  <section class="section container has-divider" id="ponuda">
  <h2 class="section-title">Ponuda</h2>
  <div class="row">
      <div class="col-xs-12 col-md 12">
          <p>Ponuda tvrtke se bazira na kompletnom opremanju ugostiteljskih objekata,uključujući rashladnu opremu,termičku i neutralnu opremu, potrebnih dimenzija i namjena. Pored pomoći pri osmišljavanju idejnih rješenja,pratimo najnovije standarde opremanja kuhinjskih prostora uz poštovanje svih sanitarnih i tehničkih uvjeta.</p>
      </div>
  </div>
  <div class="row">
      <div class="col-md-6 col-xs-6">
          <div class="btn-group">
              <button type="button" class="btn btn-default">Winterhalter</button>
              <button type="button" class="btn btn-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
              </button>
              <ul class="dropdown-menu">
                  <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="winterhalter" data-product="UC">Potpultne perilice UC serije</a>
                  </li>
                  <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="winterhalter" data-product="PT">Protočne perilice PT serije</a>
                  </li>
                  <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="winterhalter" data-product="GS">Perilice crnog posuđa GS serije</a>
                  </li>
                  <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="winterhalter" data-product="TP">Perilice s tračnim prijenosom košara</a>
                  </li>
                  <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="winterhalter" data-product="TT">Perilice s transportnom trakom</a>
                  </li>
                  <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="winterhalter" data-product="PI">Pranje i ispiranje</a>
                  </li>
                  <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="winterhalter" data-product="KW">Košare Winterhalter</a>
                  </li>
              </ul>
          </div>
      </div>
        <div class="col-md-6 col-xs-6">
            <div class="btn-group">
                <button type="button" class="btn btn-default">Convotherm</button>
                <button type="button" class="btn btn-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                    <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="convotherm" data-product="MS">Mini svijet Mini svijet</a>
                    </li>
                    <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="convotherm" data-product="ET">Convotherm 4 Easy Touch</a>
                    </li>
                    <li> <a href="#" data-toggle="modal" data-target="#body-modal" data-product-manufacturer="convotherm" data-product="ED">Convotherm 4 Easy Dial</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
    `
};
