import contact_form from "../components/contact_form";

export default {
  markup: `
    <section class="section container" id="kont" style="margin: 3em auto; margin-bottom: 5em;">
    <h2 class="section-title">Kontakt</h2>
    <div class="row">
        <div class="col-md-6">
            Javite nam se direktno.
        </div>
        <div class="col-md-6">
            ${contact_form.markup}
        </div>
    </div>
    </section>`
};