const section_clients = {
    title: 'Klijenti',
    content: `Naš zadatak, ali i strast jest riješiti vaš problem i ponuditi vam brza, pouzdana i sigurna rješenja. Takav rasplet, međutim, nebi bio ostvariv da nam klijenti nisu na prvom mjestu. Poduža lista referenci je ono što nas ohrabruje, motivira, ali i daje teret izazova koje nas svaki radni dan iznova vode u nove pustolovine.`
}

const section_knowledge = {
    title: 'Znanje',
    content: `Svakodnevne izazove, koji su pred nas postavljeni, bilo bi nemoguće obaviti bez kompetentnosti i vještine za koju je osposobljen naš stručni tim servisera. Uz znanje, iskustvo je ono što nas odvaja od drugih te daje garanciju, da će odabrana rješenja biti implementirana na siguran i profesionalan način.`
}

const section_speed = {
    title: 'Brzina',
    content: `U modernom svijetu, sigurnost i pouzdanost su uvijet nužnosti. U vremenu kada je svaka sekunda bitna, on nije i dovoljan. Da bi bili među najboljima, uz kvalitetu i profesionalnost, potrebno je posjedovati i brzinu rješenja. Uz zadovoljstvo klijenata i profesionalnost rada, brzina izvođenja je ono što naš recept čini kompletnim.`
}

const header = {
    content: `Iskustvo, znanje i povjerenje.`,
    subtitle: `Zajedno s vama od 2002.`
}

const business = {
    name: 'Bugovina',
    fullname: 'Bugovina d.o.o.',
    adress: {
        city: 'Cilipi',
        street: 'Dubrave, 8'
    }
}
const info = {
    copyright: 'Sva prava pridržana.',
    copyrightYear: '2018'
}

export let references = {
    markup: `
    <h3 class="vert-offset-1">JADRANSKI LUKSUZNI HOTELI DD :</h3>
    <h3>HOTEL CROATIA CAVTAT</h3>
    <h3>HOTEL EXCELSIOR DUBROVNIK</h3>
    <h3>HOTEL KOMPAS DUBROVNIK</h3>
    <h3>HOTEL ARGENTINA DUBROVNIK</h3>
    <h3>ATLAS HOTEL ODISEJ MLJET</h3>
    <h3>HOTEL DUBROVNIK PALACE</h3>
    <h3>HOTEL BELLEVUE DUBROVNIK</h3>
    <h3>HOTEL LIBERTAS RIXOS</h3>
    <h3>HOTELI MAESTRAL D.D. DUBROVNIK</h3>
    <h3>HOTEL PETKA D.D DUBROVNIK</h3>
    <h3>GRAFIKA d.o.o. HOTEL SUMRATIN DUBROVNIK</h3>
    <h3>RENESANSA d.o.o. HOTEL ZAGREB DUBROVNIK</h3>
    <h3>DUBROVAČKO PRIMORJE d.o.o - HOTEL ADMIRAL SLANO</h3>
    <h3>GRAND HOTEL PARK D.O.O. DUBROVNIK</h3>
    <hr>
    <h3>DUBROVAČKI VRTOVI SUNCA D.O.O. ORAŠAC</h3>
    <h3>RIVIERA ADRIA DD POREČ – HOTELI VALAMAR DUBROVNIK</h3>
    <h3>HOTEL LACROMA DUBROVNIK</h3>
    <h3>HOTEL TIRENA</h3>
    <h3>HOTEL ARGOSY</h3>
    <h3>HOTEL DUBROVNIK PRESIDENT</h3>
    <h3>HOTEL VALAMAR CLUB</h3>
    <h3>HOTELI CAVTAT DD CAVTAT</h3>
    <h3>REZIDENCIJE CAVTAT d.o.o. – HOTEL CAVTAT,CAVTAT</h3>
    <h3>ANKER GRUPA d.o.o. – HOTEL LAFODIA-OTOK LOPUD</h3>
    <h3>HOTEL LERO DUBROVNIK</h3>
    <h3>HILTON IMPERIAL DUBROVNIK</h3>
    <h3>HOTEL OSMINE SLANO</h3>
    <h3>HOTELI DUBROVAČKA RIVJERA MLINI</h3>
    <h3>HOTEL ASTAREA</h3>
    <h3>HOTEL MLINI</h3>
    <hr>
    <h2 class="divider">Kao i ustanove </h>
    <h3>Dom za starije i nemoćne osobe Dubrovnik </h3>
    <h3>Dom za starije i nemoćne osobe Metković</h3>
    <h3>Dom za starije i nemoćne osobe Domus Chriti</li>
    <h3>Ženski đački dom Dubrovnik</h3>
    <h3>Osnovna škola Marina Držića Dubrovnik</h3>
    <h3>Dječji vrtići Dubrovnik</h3>
    <h3>Dječji vrtići Konavle</h3>
    <h3>Centar za rehabilitaciju Josipovac</h3>
    <h3>Ženski učenički dom Paola di Rosa</h3>
    <h3>Muški učenički dom Dubrovnik</h3>
    <h3>Dom za djecu i mlađe punoljetne osobe 'Maslina' Dubrovnik</h3>
    <h3>Franjevački samostan Male braće Dubrovnik</h3>


    <h2 class="divider">Druga ugledna poduzeća</h2>
    <h3>NEWREST DUBROVNIK d.o.o.</h3>
    <h3>ZRAČNA LUKA DUBROVNIK d.o.o</h3>
    <h3>MEA CULPA d.o.o</h3>
    <h3>NAUTIKA d.o.o</h3>
    <h3>ESCULAP TEO d.o.o</h3>
    <h3>DOMINO d.o.o</h3>
    <h3>KLARISA d.o.o</h3>
    <h3>JADRANSKA KRSTARENJA d.o.o</h3>
    <h3>SAINTS HILLS d.o.o</h3>
    <h3>ZANETTI d.o.o</h3>
    <h3>VILLA WOLFF DUBROVNIK</h3>
    <h3>GEMINI NJAM d.o.o. CAVTAT</h3>
    <h3>KING COMMERCE d.o.o. CAVTAT</h3>
    <h3>EXTRA d.o.o. CAVTAT</h3>
    <h3>COSTERIA d.o.o. MLINI</h3>
    <h3>ZINO d.o.o. CAVTAT</h3>
    <h3>GVEROVIĆ ORSAN-ZATON</h3>
    <h3>KONOBA IVAN CAVTAT</h3>
    <h3>CAVTATSKI DELFIN d.o.o. CAVTAT</h3>
    <h3>PERGOLA d.o.o. GRUDA</h3>
    <h3>GRGIĆ VINA d.o.o. METKOVIĆ</h3>
    <h3>NARONA TOURS d.o.o. METKOVIĆ</h3>
    <h3>KONOBA GALIJA d.o.o. CAVTAT</h3>
    <h3>EL PRESIDENTE C.O. d.o.o. DUBROVNIK</h3>
    `
}
