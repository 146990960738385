import logo from "../images/bug.png";

export default {
  markup: `
    <div class="jumbotron">
                <div class="container">
                    <!--<img src="${logo}">-->
                    <img src="${logo}" class="img-responsive hidden" style="">
                    <p class="jumbotron-title">Iskustvo, znanje i povjerenje.</p>
                    <p class="jumbotron-description">od 2002.</p>

                    <!--
                    <h3 class="jumbotron-title text-left hidden">Iskustvo, znanje i povjerenje.</h3>
                    <h3 class="jumbotron-description text-left hidden">S vama od 2002.</h3>
                    -->
                    <!--<p><a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a></p>-->
                </div>
            </div>
    `
};
